/* button styles
 ------------------------------ */

.btn {
    padding: 10px 36px;
    margin: 0px;
    box-shadow: none;
    border-radius: 0;
    font-size: 14px;

    &.btn-red {
        background-color: $primary;
        color: $white;

        &:hover {
            border: 1px solid $primary;
            background-color: transparent;
            color: $primary;
        }
    }
}