.contact {
	.info-item {
		padding: 20px 15px 10px;
		box-shadow: 0px 0px 20px rgba(54, 77, 89, 0.1);
		// margin-bottom: 20px;
		height: 100%;
		text-align: center;

		h4 {
			font-weight: bold;
		}

		i {
			color: $primary;
			margin: 0 auto;
			margin-bottom: 15px;
			width: 56px;
			height: 56px;
			font-size: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all 0.3s ease-in-out;
			border-radius: 50%;
			border: 2px dotted rgba($primary, 0.6);
		}
	}

	.php-email-form {
		height: 100%;
		padding: 30px;
		box-shadow: 0px 0px 20px rgba(54, 77, 89, 0.1);
	}
}