// Main SCSS

@import 'settings';
@import 'font';
@import 'base';
@import 'typography';
@import 'button';
@import 'form';
@import 'header';
@import 'footer';
@import 'component';
@import 'home';