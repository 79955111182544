#inquiry {

    input[type="text"],
    input[type="email"],
    input[type="number"],
    textarea,
    select {
        background-color: $primary;
        color: $white;
        border-color: $white;
        margin-bottom: 20px;
        padding: 7px;
        border: 1px solid white;
        width: 100%;

        &::-webkit-input-placeholder {
            color: $white;
        }
    }
}

input[type="text"],
input[type="email"],
input[type="number"],
textarea,
select {
    background-color: transparent;
    color: black;
    border-color: $white;
    margin-bottom: 20px;
    padding: 10px;
    // padding-left: 36px;
    border: 1px solid #efefef;
    width: 100%;

    &::-webkit-input-placeholder {
        color: gray;
    }
}

#inquiry .btn.btn-gyellow-2 {
    background-color: $secondary !important;
}

/* =========== forms ============= */
.smart-forms {
    .input-hint {
        padding: 10px;
        display: block;
        margin-top: -1px;
        line-height: 16px;
        position: relative;
        background: #F5F5F5;
        border: 1px solid #CFCFCF;
        font-family: Arial, Helvetica, sans-serif;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
        font-size: 11px;
        color: #999;
    }

    .form-footer {
        padding: 0;
    }

    .btn-cancel {
        // color: #243140;
        font-size: 15px;
        // text-align: center;
        background: #DBDBDB;
    }

    .btn-primary {
        background-color: $primary !important;
    }

    .form-body {
        width: 100%;
        padding: 40px;
        float: left;

        .prepend-icon {
            position: relative;
            width: 100%;

            &:focus {
                .field-icon {
                    i {
                        color: $secondary;
                    }
                }
            }

            >* {

                &:hover,
                &:focus {
                    border: 1px solid $secondary;
                    outline: none;
                }
            }

            .field-icon {
                top: 0;
                // position: absolute;
                // top: 10px;
                // left: 13px;
            }
        }
    }
}