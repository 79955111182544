// paddings

.top-padding {
    padding-top: 50px;
}

.section {
    padding: 50px 0;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

// page banner

.page-banner {
    background-image: url("../assets/images/inner-back.webp") !important;
    background-repeat: no-repeat;
    height: 220px;
    position: relative;
    background-size: cover;
    background-position: center;

    @media(max-width: 767px) {
        height: 175px;
    }

    &::before {
        content: '';
        background-color: rgba(19, 98, 144, 0.7);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .page-title {
        position: absolute;
        z-index: 99;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h3 {
            text-transform: uppercase;
            color: $white;
            font-weight: 600;
            font-size: 30px;
            margin-bottom: 0;
            text-align: center;

            @media(max-width: 767px) {
                font-size: 22px;
            }
        }
    }
}

/* title line
 ------------------------------ */
.ce4-title-line-1 {
    width: 50px;
    height: 2px;
    margin: 0px auto 10px auto;
    background-color: $codGray;

    &.align-left {
        width: 50px;
        margin: 0 auto 20px 0;
    }
}

// social icons (sticky)

.social-wrap {
    left: 0;
    bottom: 120px;
    position: fixed;
    z-index: 9;

    ul {
        padding-left: 0;

        li {
            margin-bottom: 10px;
            width: 45px;
            height: 45px;

            a {
                padding: 10px;
                background: $primary;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                height: 100%;

                &:hover {
                    background-color: $secondary;
                }
            }
        }
    }
}

/* image rounded
 ------------------------------ */

.img-rounded {
    border-radius: 8px;
}


/*============ Feature boxes ============ */
.p-5 {
    padding: 2em;
}

.tp-sec-bg {
    width: 100%;
    background: #f5f5f5;
}

/*============ Section Titles ============ */

/* title line
 ------------------------------ */
.tp-title-line-1 {
    width: 70px;
    height: 2px;
    margin: 15px auto 10px auto;
    background-color: $codGray;

    &.align-left {
        width: 70px;
        margin: 8px auto 20px 0;
    }

    &.dark {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    &.white {
        background-color: $white;
    }
}

// Loader design
.over-loader {
    background: #ed5565;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;

    &.loader-live {
        z-index: 9999991;
        background: #fff;
    }

    .loader {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        z-index: 99999;
    }

    .loader-item.style4 {
        width: 40px;
        height: 40px;
        position: relative;

        .cube1,
        .cube2 {
            background-color: #136290 !important;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 0;
            left: 0;
            -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
            animation: sk-cubemove 1.8s infinite ease-in-out;
        }

        .cube2 {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }
    }
}

/* line 23, ../sass/screen.scss */
.hide-loader {
    display: none;
}

@keyframes sk-cubemove {
    25% {
        transform: translateX(42px) rotate(-90deg) scale(0.5);
        -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    }

    50% {
        transform: translateX(42px) translateY(42px) rotate(-179deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    }

    50.1% {
        transform: translateX(42px) translateY(42px) rotate(-180deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    }

    75% {
        transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
        -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    }

    100% {
        transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
    }
}