// .desktop-view {
//     display: block !important;

//     @media (max-width:767px) {
//         display: none !important;
//     }
// }

// .mobile-view {
//     display: none !important;

//     @media (max-width:767px) {
//         display: block !important;
//     }
// }

.product-wrap {
    margin-bottom: 30px;
}

.icon-box {
    text-align: center;
    border: 1px solid #ebebeb;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
    cursor: pointer;

    &:hover {
        border-color: #fff;
        box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
        transform: translateY(-10px);
    }
}