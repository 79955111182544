/**
 * SETTINGS
 *  - Color Variable
 */

//  Color Variable
$black: #000;
$white: #fff;
// $red: #F00;

$primary: #803238;
$secondary: #7bc14b;
$codGray: #101010;
$blue: #226bb4;
// $orange: #fc3;
// $orange-dark: rgb(255, 154, 1);
// $light-gray: #b6c9b6;