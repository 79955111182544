html {
  scroll-behavior: smooth;
}

body {
  font: 14px 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 23px;
  color: #727272;
}

/* a link Styles
 ------------------------------ */

a,
.btn {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
a:hover {
  color: $codGray;
}

a {
  outline: medium none !important;
  color: #727272;
}

/* Headungs
 ------------------------------ */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  color: #272727;

  a {
    color: inherit;
  }
}

h1 {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 20px;
  font-weight: 300;

  &.less-mar-1 {
    margin-bottom: 10px;
  }
}

h2 {
  font-size: 35px;
  line-height: 35px;
  margin-bottom: 20px;
  font-weight: 300;

  &.less-mar-1 {
    margin-bottom: 3px;
  }
}

h3 {
  font-size: 27px;
  line-height: 30px;
  margin-bottom: 18px;

  @media(max-width: 767px) {
    font-size: 22px;
  }

  &.less-mar-1 {
    margin-bottom: 3px;
  }
}

h4 {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 18px;

  &.less-mar-1 {
    margin-bottom: 3px;
  }

  @media(max-width: 767px) {
    font-size: 20px;
  }
}

h5 {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 16px;

  &.less-mar-1 {
    margin-bottom: 3px;
  }
}

h6 {
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 14px;

  &.less-mar-1 {
    margin-bottom: 3px;
  }
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

ul {
  list-style-type: none;
}

/*=============== Text Colors ============= */

// .text-white {
//   color: $white;
// }

.text-dark {
  color: $codGray;
}

.text-gyellow {
  color: $primary !important;
}

/*=============== Margins ============= */
.divider-margin {
  width: 100%;
  float: left;

  &.col-divider-margin-2 {
    margin-top: 20px;
  }

  &.col-divider-margin-3 {
    margin-top: 30px;
  }

  &.col-divider-margin-5 {
    margin-top: 50px;
  }

  &.col-divider-margin-6 {
    margin-top: 60px;
  }

  @media(max-width: 991px) {
    margin-top: 20px;
  }
}

.col-centered {
  float: none;
  margin: 0 auto;
}

/*=============== Divider lines ============= */
.divider-line {
  float: left;
  width: 100%;

  &.solid {
    border-bottom: 1px solid;
  }

  &.light {
    border-bottom-color: #f1f1f1;
  }
}

/*=============== Opacity styles ============= */

.opacity-8 {
  opacity: 0.8;
}


/* font weight styles
 ------------------------------ */

.font-weight-3 {
  font-weight: 300;
}

.font-weight-4 {
  font-weight: 400;
}

.font-weight-5 {
  font-weight: 500;
}

.font-weight-6 {
  font-weight: 600;
}

.font-weight-7 {
  font-weight: 700;
}

/*=============== site main structure ============= */

.site-wrapper {
  width: 100%;
  margin: 0px auto;
  background-color: $white;
}

.wrapper-boxed {
  margin: auto;
  background-color: $white;
}

/*=============== Icon Boxes ============= */

.iconbox-xmedium,
.iconbox-small {
  padding: 0px;
  margin: 0 auto;
  text-align: center;
  transition: all 0.3s ease-out 0s;
  color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;

  &.round {
    border-radius: 100%;
  }

  &.left {
    float: left;
    // margin: 0px 20px 0px 0px;
  }
}

.iconbox-xmedium {
  width: 110px;
  height: 110px;
  font-size: 38px;

  @media(max-width: 767px) {
    width: 80px;
    height: 80px;
    font-size: 30px;
  }

  @media(max-width: 575px) {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
}

.iconbox-small {
  width: 80px;
  height: 80px;
  font-size: 26px;
  line-height: 80px;
}

/*============ Text Boxes ============ */

.text-box-right {
  display: block;
  padding: 0px 0px 0px 0px;
  margin: 0px;
  text-align: left;

  &.more-padding-1 {
    padding: 0px 0px 0px 120px;

    @media(max-width: 575px) {
      padding: 0px 0px 0px 100px;
    }
  }

  &.more-padding-2 {
    padding: 0px 0px 0px 140px;

    @media(max-width: 767px) {
      padding: 0px 0px 0px 100px;
    }

    @media(max-width: 575px) {
      padding: 0px 0px 0px 70px;
    }
  }

  &.less-padding-2 {
    padding: 0px 0px 0px 50px;
  }

  &.less-padding-3 {
    padding: 0px 0px 0px 40px;
  }

  @media (max-width: 1024px) {
    padding: 0px 0px 0px 100px;
  }

  @media(max-width: 360px) {
    width: 100%;
    padding: 0 0 0 0;
  }
}

.item-holder {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

/* text boxe padding
 ------------------------------ */
.text-box {
  width: 100%;
  float: left;

  &.border {
    border: 1px solid #f1f1f1;
  }

  &.white {
    background-color: #fff;
  }

  &.padding-3 {
    padding: 30px;
  }

  &.padding-4 {
    padding: 40px;
  }
}

/*============ Buttons ============ */
a {
  &.read-more {
    color: $primary !important;

    &:hover {
      color: $codGray;
    }

    &.gyellow-2 {
      color: $primary !important;

      &:hover {
        color: $white;
      }
    }
  }
}

/* icon list style 2
 ------------------------------ */
.iconlist-2 {
  width: 100%;
  padding: 0px;
  margin: 0px 0px 10px 0px;
  float: left;

  .icon {
    width: 18px;
    height: 18px;
    padding: 0px;
    margin: 3px 0px 0px 0px;
    float: left;
    font-size: 14px;
    text-align: center;
    line-height: 15px;
    color: #cc0000;
    border-radius: 100%;

    &.dark {
      color: $codGray;
    }
  }

  .text {
    padding: 0px 0 0 28px;
    margin: 0px;
    display: block;
  }
}

/* Sticky bg
 ------------------------------ */
#header {
  position: relative;
  background: $codGray;
  padding: 0;
  width: 100%;
  z-index: 999;
}

/* title container
 ------------------------------ */
.sec-title-container {
  width: 100%;
  float: left;
  padding-bottom: 70px;

  &.less-padding-3 {
    padding-bottom: 40px;
  }
}

/* section background light
 ------------------------------ */
.section-light {
  background-color: #f5f5f5;
}

/* Section Paddings
 ------------------------------ */
.sec-padding {
  padding: 60px 0 60px 0;
}

.sec-tpadding-2 {
  padding-top: 80px;

  &.less-padding {
    padding-top: 20px;
  }
}

.sec-bpadding.less-padding {
  padding-bottom: 30px;
}

.sec-moreless-padding {
  padding: 30px 0 30px 0;
}

/*============ Overlay Styles ============ */

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  float: left;
  z-index: 10;
}

// .bg-opacity-8 {
//   background-color: $primary;
// }

/*============ Video wrapers ============ */

.video-wrapper {
  overflow: hidden;
  width: 100%;
  padding: 0px;
  height: auto;

  video {
    width: 100%;
  }
}

/*============ Page Scroll to Top ============ */

.scrollup {
  width: 40px;
  height: 40px;
  opacity: 1;
  position: fixed;
  bottom: 22px;
  right: 20px;
  display: none;
  text-indent: -9999px;
  background: $primary url("../assets/images/scroll-top-arrow.png") no-repeat 15px 16px !important;
  z-index: 999;

  &:hover {
    opacity: .8;
  }
}

/*----------------------------RESPONSIVE STYLES------------------------------- */

/* ---------- MAX 991PX ---------- */
@media(max-width: 991px) {

  .sec-title-container.less-padding-1,
  .sec-title-container.less-padding-2,
  .sec-title-container.less-padding-3,
  .sec-title-container.less-padding-4,
  .sec-title-container.less-padding-5,
  .sec-title-container.less-padding-6 {
    padding-bottom: 30px;
  }
}

/* ---------- MAX 800PX ---------- */
@media(max-width: 800px) {

  .sec-padding {
    padding: 60px 0 60px 0;
  }

  .sec-bpadding-2,
  .sec-bpadding-3,
  .sec-bpadding-4 {
    padding-bottom: 60px;
  }

  .sec-tpadding-2,
  .sec-tpadding-3,
  .sec-tpadding-4 {
    padding-top: 60px;
  }

  .sec-moreless-padding {
    padding: 60px 0 60px 0;
  }

}

/* ---------- MAX 768PX ---------- */
@media only screen and (max-width: 768px) {

  .sec-bpadding-2,
  .sec-bpadding-3,
  .sec-bpadding-4 {
    padding-bottom: 60px;
  }

  .sec-tpadding-2,
  .sec-tpadding-3,
  .sec-tpadding-4 {
    padding-top: 60px;
  }

  .sec-moreless-padding {
    padding: 60px 0 60px 0;
  }
}

/* ---------- MAX 767PX ---------- */

@media screen and (max-width: 640px) {

  .sec-padding {
    padding: 40px 0 40px 0;
  }

  .sec-bpadding-2,
  .sec-bpadding-3,
  .sec-bpadding-4 {
    padding-bottom: 40px;
  }

  .sec-tpadding-2,
  .sec-tpadding-3,
  .sec-tpadding-4 {
    padding-top: 40px;
  }

  .sec-moreless-padding {
    padding: 40px 0 40px 0;
  }

  #style-customizer {
    display: none;
  }

  .text-box.sbox-padd-left {
    padding-left: 80px;
  }

  .section-side-image .text-inner-5 {
    padding: 40px 40px 40px 40px;
  }

}

@media screen and (max-width: 480px) {

  .sec-padding {
    padding: 40px 0 40px 0;
  }

  .sec-bpadding-2,
  .sec-bpadding-3,
  .sec-bpadding-4 {
    padding-bottom: 40px;
  }

  .sec-tpadding-2,
  .sec-tpadding-3,
  .sec-tpadding-4 {
    padding-top: 40px;
  }

  .sec-moreless-padding {
    padding: 40px 0 40px 0;
  }

  #style-customizer {
    display: none;
  }

  .section-side-image .text-inner-5 {
    padding: 40px 40px 40px 40px;
  }
}

/* ---------- MAX 360PX ---------- */
@media screen and (max-width: 360px) {

  .iconbox-xmedium.left,
  .iconbox-small.left {
    margin: 0px 0px 20px 0px;
  }

  .section-side-image .text-inner-2 {
    padding: 40px;
  }

  .section-side-image .text-inner {
    padding: 40px 40px 40px 0;
  }

  .section-side-image .text-inner.two {
    padding: 0px 40px 0px 0;
  }

  .section-side-image .text-inner-5 {
    padding: 40px 40px 40px 40px;
  }

  .sec-padding {
    padding: 40px 0 40px 0;
  }

  .sec-bpadding-2,
  .sec-bpadding-3,
  .sec-bpadding-4 {
    padding-bottom: 40px;
  }

  .sec-tpadding-2,
  .sec-tpadding-3,
  .sec-tpadding-4 {
    padding-top: 40px;
  }

  .sec-moreless-padding {
    padding: 40px 0 40px 0;
  }

}

/* ---------- MAX 320PX ---------- */
@media only screen and (max-width: 320px) {

  .iconbox-xmedium.left,
  .iconbox-small.left {
    margin: 0px 0px 20px 0px;
  }

  .section-side-image .text-inner {
    padding: 40px 40px 40px 0;
  }

  .section-side-image .text-inner.two {
    padding: 0px 40px 0px 0;
  }

  .sec-padding {
    padding: 30px 0 30px 0;
  }

  .sec-bpadding-2,
  .sec-bpadding-3,
  .sec-bpadding-4 {
    padding-bottom: 30px;
  }

  .sec-tpadding-2,
  .sec-tpadding-3,
  .sec-tpadding-4 {
    padding-top: 30px;
  }

  .sec-moreless-padding {
    padding: 30px 0 30px 0;
  }

  .sec-title-container {
    padding-bottom: 35px;
  }
}

.chem-bg {
  background-image: linear-gradient(45deg, rgb(25, 104, 147), rgb(86, 185, 71));
  padding: 1em;
}

.install-box {
  width: 100%;
  height: 250px;
}

.abt-link {
  color: $secondary;

  &:hover {
    color: #009fff;
  }
}


.knowmore_btn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}