// @font-face {
//     font-family: 'Roboto';
//     src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('ttf');
//     src: url('../assets/fonts/Roboto/Roboto-Regular.woff') format('woff');
//     src: url('../assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2');
//     src: url('../assets/fonts/Roboto/Roboto-Regular.eot') format('eot');
//     src: url('../assets/fonts/Roboto/Roboto-Regular.svg') format('svg');
//     font-display: fallback;
// }

// $roboto: 'Roboto', sans-serif;

// Roboto

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900&display=swap');

// Open Sans

@import url('https://fonts.googleapis.com/css?family=Open+Sans:ital,wght@0,300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');